<template>
	<!-- eslint-disable max-len -->
	<div
		@click="$root.$i18n.locale !== lang ? $emit('selected', lang) : null"
		class="flex items-center p-4 cursor-pointer LanguageSwitchItem bg-day-04dp dark:bg-night-04dp rounded-xl"
		:class="{ 'opacity-50 cursor-default': comingSoon || $root.$i18n.locale === lang }"
	>
		<AppIcon :icon-name="`flag-${lang}`" :width="24" :height="24" />
		<span class="ml-2"
			>{{ label }} {{ $root.$i18n.locale === lang ? $t('account.language.selected') : '' }}
			<span v-if="comingSoon" class="ml-2 text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{
				$t('account.language.coming-soon')
			}}</span>
		</span>
	</div>
</template>

<script>
import AppIcon from '@/components/Interface/AppIcon.vue';

export default {
name: 'LanguageSwitchItem',

	props: {
		lang: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		comingSoon: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		AppIcon,
	},
};
</script>

<style lang="scss" scoped>
.LanguageSwitchItem {
}
</style>
