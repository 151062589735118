<template>
	<!-- eslint-disable max-len -->
	<BaseDrawer @close="$emit('close')" class="LanguageSwitchDrawer">
		<div class="px-4">
			<header class="LanguageSwitchDrawer_Header">
				<h1 class="mb-1">{{ $t('account.language.title') }}</h1>
				<p class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">
					{{ $t('account.language.description') }}
				</p>
			</header>

			<div class="grid row-gap-4 mt-6 LanguageSwitchDrawer_Languages">
				<LanguageSwitchItem lang="en" label="English" @selected="switchLanguage($event)" />
				<LanguageSwitchItem lang="nl" label="Dutch" @selected="switchLanguage($event)" />
				<LanguageSwitchItem lang="fr" label="French" @selected="switchLanguage($event)" />
			</div>
		</div>
	</BaseDrawer>
</template>

<script>
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import LanguageSwitchItem from '@/components/LanguageSwitchItem.vue';
import useReseller from '@/composables/useReseller';
import dayjs from 'dayjs';

export default {
	name: 'LanguageSwitchDrawer',

	components: {
		BaseDrawer,
		LanguageSwitchItem,
	},

	setup(props, { emit, root }) {
		const { fetchResellerData } = useReseller();

		/**
		 * Method to change the current app language
		 *
		 * @param {String} lang Language ID
		 * @returns {void}
		 */
		const switchLanguage = async locale => {
			/* eslint-disable no-param-reassign */
			root.$i18n.locale = locale;
			root.$http.defaults.headers.common['x-localization'] = locale;

			dayjs.locale(root.$i18n.locale);

			// Save preferred language to local storage
			localStorage.setItem('preferred-locale', locale);

			// Clear reseller data to referch content pages
			await fetchResellerData();

			// Close drawer
			emit('close');
		};

		return {
			switchLanguage,
		};
	},
};
</script>

<style lang="scss" scoped>
.LanguageSwitchDrawer {
}
</style>
